// Send Google Event Function
function sendGA(cat, action, label) {
  ga('send', 'event', ''+cat+'', ''+action+'', ''+label+'');

  return true;
}

// Check for valid email address
function isValidEmailAddress(emailAddress) {
    var pattern = new RegExp(/^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/);
    return pattern.test(emailAddress);
};

// Functions to manage booking form (taken from existing site)
function datechange() {
	date_a = document.getElementById('datepicker1').value;
	var sliptdate_a = date_a.split("-");
	var d = sliptdate_a[0];
	var m = sliptdate_a[1];
	var y = sliptdate_a[2];
	var ddy=m+"/"+d+"/"+y;
	var NextDate= new Date(ddy);

	function daysInMonth(month,year) {
		return new Date(year, month, 0).getDate();
	}

	//alert(daysInMonth(m,y)); //31

	var nextday =NextDate.getDate() + 1;
	var nextmonth =NextDate.getMonth() +1;

	if(nextday>daysInMonth(m,y)){
		var nextday =01;
		var nextmonth =NextDate.getMonth() +2 ;
	}

	nextyear= NextDate.getFullYear();

	if(nextmonth>12){
		nextmonth= 1;
		nextyear=nextyear+1;
	}

	if(nextday<10){
		nextday= "0"+nextday;
	}

	if(nextmonth<10){
		nextmonth= "0"+nextmonth;
	}

	var Ndate=nextday+"-"+nextmonth+"-"+nextyear;
	// alert(Ndate);
	if(Ndate!="NaN-NaN-NaN"){
		document.getElementById('datepicker2').value=Ndate;
	}
}

function insertval() {

	var dateReg = /^\d{2}[.-]\d{2}[.-]\d{4}$/;

	date = document.getElementById('datepicker1').value;
	var sliptdate = date.split("-");
	date_c = document.getElementById('datepicker2').value;
	var sliptdate_c = date_c.split("-");

	if (date=="") {

		alert("To view availability, please enter your dates.")
		document.getElementById('datepicker1').focus();

		return false;
	}

	if(!(document.getElementById('datepicker1').value.match(dateReg))){
		alert("Wrong Date Typed in check in date field !\n Correct format is: (DD-MM-YY)");
		return false;
	}

	if (date_c=="") {
		alert("To view availability, please enter your dates.")
		document.getElementById('datepicker2').focus();
		return false;
	}

	if(!(document.getElementById('datepicker2').value.match(dateReg))){
		alert("Wrong Date Typed in check out date field !\n Correct format is: (DD-MM-YY)");
		document.getElementById('checkoutdate').focus();
		return false;
	}

	if (date_c==date) {
		alert("Please do not select same date in checkout !")
		document.getElementById('datepicker2').focus();

		return false;
	}

	if (Date.parse(sliptdate[1]+"/"+sliptdate[0]+"/"+sliptdate[2]) > Date.parse(sliptdate_c[1]+"/"+sliptdate_c[0]+"/"+sliptdate_c[2])) {
		alert("Your check-out date is before your check-in date. Have another look at your dates and try again.")
		document.getElementById('datepicker2').focus();
		return false;
	}

    document.getElementById('checkInDayD').value = sliptdate[0];
    document.getElementById('checkInMonthYearD').value = sliptdate[1] - 1 + "" + sliptdate[2];
    document.getElementById('checkOutDayD').value = sliptdate_c[0];
    document.getElementById('checkOutMonthYearD').value = sliptdate_c[1] - 1 + "" + sliptdate_c[2];
    document.getElementById('numberOfRoomsD').value = document.getElementById('rooms').value;
    document.getElementById('numberOfAdultsD').value = document.getElementById('adults').value;
    document.getElementById('numberOfChildrenD').value = document.getElementById('children').value;
    document.getElementById('checkInDay').value = sliptdate[0];
    document.getElementById('checkInMonthYear').value = sliptdate[1] - 1 + "" + sliptdate[2];
    document.getElementById('checkOutDay').value = sliptdate_c[0];
    document.getElementById('checkOutMonthYear').value = sliptdate_c[1] - 1 + "" + sliptdate_c[2];
    document.getElementById('numberOfRooms').value = document.getElementById('rooms').value;
    document.getElementById('numberOfAdults').value = document.getElementById('adults').value;
    document.getElementById('numberOfChildren').value = document.getElementById('children').value;
    
    var loc = $('.bookingSubmit').data('location');
    
    ga('send', 'event', 'Successful Search', 'clicked', 'Gone to booking website from '+loc+'');
    
    document.frmBook.submit();
    document.getElementById('datepicker1').value = sliptdate[0] + "-" + sliptdate[1] + "-" + sliptdate[2];
    document.getElementById('datepicker2').value = sliptdate_c[0] + "-" + sliptdate_c[1] + "-" + sliptdate_c[2];	
    //insertval();
}

var device = $('.detectDevice').css("float");
if(device=="right" || device=="left") {
    $("#frmBook").attr("action", "https://m.ihg.com/hotels/holidayinn/gb/en/hoteldetail/CRYUK?&numberOfAdults=&numberOfChildren=&checkOutMonthYear=&checkInDay=&checkOutDay=&hotelCode=CRYUK&PMID=99502222");
}

$(function () {
	// Open Review panel

    if ($(".fixedReviewsButton")[0]) {
        $('.fixedReviewsButton').click(function() {
                var loc = $(this).data('location');

            ga('send', 'event', 'Review Panel Opened', 'clicked', 'Review panel opened on '+loc);

            var device = $('.reviewDetectDevice').css("float");

                                                if(device=='left') {
                            $('.fixedReviewsButton').animate({
                                right: '-302px'
                            }, 'slow');
                        } else {
                                $('.fixedReviewsButton').animate({
                                right: '0px'
                            }, 'slow');
                       }

            $('.reviewsContainer').animate({
                right: '0px'
            }, 'slow');
        });
    } 

    // Close review panel

    if ($(".reviewsClose")[0]) {
        $('.reviewsClose').click(function() {

                var device = $('.reviewDetectDevice').css("float");

                                                if(device=='left') {
                            $('.fixedReviewsButton').animate({
                                right: '30px'
                            }, 'slow');
                        } else {
                                $('.fixedReviewsButton').animate({
                                right: '0px'
                            }, 'slow');
                        }

            $('.reviewsContainer').animate({
                right: '-360px'
            }, 'slow');
        });
    }


// Set up booking form date pickers
	$('#datepicker1').datetimepicker({
    	format: 'DD-MM-YYYY'
    });

    $('#datepicker2').datetimepicker({
    	format: 'DD-MM-YYYY'
    });

    // Hide fixed footer buttons when at top of the page
    var fixedButtons = function fixedButtons() {
		if ($(this).scrollTop() > 0) {
			$(".footerFixedButtons").removeClass("footerFixedButtonsHidden");
		} else {
			$(".footerFixedButtons").addClass("footerFixedButtonsHidden");
		}
    };

    $(window).on("scroll", fixedButtons);

    // Set menu to fixed on page scroll
    var fixedMenu = function fixedMenu() {
    	var device = $('.detectDevice').css("float");
		if(device=='right') {
			if ($(this).scrollTop() > 164) {
				$(".headerContainer").addClass("headerContainerFixed");
				$('.homepageBannerContainer').css('margin-top', '52px');
				$('.subPageBannerContainer').css('margin-top', '52px');
			} else {
				$(".headerContainer").removeClass("headerContainerFixed");
				$('.homepageBannerContainer').css('margin-top', '0px');
				$('.subPageBannerContainer').css('margin-top', '0px');
			}
		} else {
			if ($(this).scrollTop() > 107) {
				$(".headerContainer").addClass("headerContainerFixed");
				$('.homepageBannerContainer').css('margin-top', '52px');
				$('.subPageBannerContainer').css('margin-top', '52px');
			} else {
				$(".headerContainer").removeClass("headerContainerFixed");
				$('.homepageBannerContainer').css('margin-top', '0px');
				$('.subPageBannerContainer').css('margin-top', '0px');
			}
		}
    };

    $(window).on("scroll", fixedMenu);

    // Set booking form to fixed on page scroll
    var fixedBooking = function fixedBooking() {
    	var device = $('.detectDevice').css("float");
		if(device=='right') {
			if ($(this).scrollTop() > 164) {
				$(".bookingContainer").addClass("bookingContainerFixed");
			} else {
				$(".bookingContainer").removeClass("bookingContainerFixed");
			}
		} else {
			if ($(this).scrollTop() > 107) {
				$(".bookingContainer").addClass("bookingContainerFixed");
			} else {
				$(".bookingContainer").removeClass("bookingContainerFixed");
			}
		}
    };

    $(window).on("scroll", fixedBooking);

	/*// Script to manage homepage counter animations
    var fx = function fx() {
	    $(".hpCounter").each(function (i, el) {
	        var data = parseInt(this.dataset.n, 10);
	        var timer = parseInt(this.dataset.t, 10);
	        var props = {
	            "from": {
	                "count": 0
	            },
	                "to": {
	                "count": data
	            }
	        };
	        $(props.from).animate(props.to, {
	            duration: 1000 * timer,
	            step: function (now, fx) {
	                $(el).text(Math.ceil(now));
	            },
	            complete:function() {
	                if (el.dataset.sym !== undefined) {
	                  el.textContent = el.textContent.concat(el.dataset.sym)
	                }
	            }
	        });
	    });
    };
    
    // Play counter animation once when visible
    var reset = function reset() {
        if($('.hpCounter').visible( true)) {
			$(this).off("scroll");
			$(window).on("scroll", menuReset);
       		fx();
		}
    };

    // set menu position based on scroll position
    var menuReset = function menuReset() {
		if ($(this).scrollTop() > 0) {
			$(".hpNavContainer").addClass("hpNavContainerFixed");
			$(".mobileToTop").removeClass("mobileToTopHidden");
		} else {
			$(".hpNavContainer").removeClass("hpNavContainerFixed");
			$(".mobileToTop").addClass("mobileToTopHidden");
		}
    };
    
    $(window).on("scroll", reset);

    $(window).on("scroll", menuReset);*/
}); 

$(document).ready(function() {

	if($(".tweet")[0]) {
		$('.tweet').twittie({
			'apiPath':'/api/tweet.php',
			'username': "HiExCrawley",
		    'count': 2,
		    'hideReplies': true
		});
	}
	

	// Add subscription form funcionality
	$(".subscriptionSubmit").click(function (e) {
    	    e.preventDefault();
    	    
	    var formData = {
	        'success'              : $('input[name=ysnSuccess]').val(),
	        'email'             : $('input[name=strSubscription]').val()
	    };

	    // process the form
	    $.ajax({
	        type        : 'POST', // define the type of HTTP verb we want to use (POST for our form)
	        url         : '/api/subscription/subscribe', // the url where we want to POST
	        data        : formData, // our data object
			success    : function(data){
				if(data !== '') {
					// If the error message field is already open just change the message.
					if ($('.subscriptionMessage').is(':visible')) {
						$('.subscriptionMessage p').html(data[1]);
					} else {
						$('.subscriptionMessage p').html(data[1]);
						$('.subscriptionMessage').slideDown();
						$('.subscriptionMessage').delay(6000).slideUp();
					}
				}			
			},
			error     : function(data) {
				var data = JSON.parse(data.responseText);
				console.log(data);
			
				// If the error message field is already open just change the message.
					if ($('.subscriptionMessage').is(':visible')) {
						$('.subscriptionMessage p').html(data.email[0]);
					} else {
						$('.subscriptionMessage p').html(data.email[0]);
						$('.subscriptionMessage').slideDown();
						$('.subscriptionMessage').delay(6000).slideUp();
					}
			}
	    })
	});


	$(".accordion-button").click(function() {
		var suite = $(this).data("suite");
		//alert(loc);
		$(".accordion-content").each(function () {
			$(this).removeClass("active");
			$(this).slideUp();
		});
		
		$("h3").each(function () {
			$(this).removeClass("active");
		});
		
		
		$("."+suite).slideToggle();
		$("."+suite).addClass("active");
		$(this).addClass("active");
	});
	
	window.onload = function() {
		$(".accordion-button").first().addClass("active");
		$(".empire").slideToggle();
	};

	
		
	if($(".readMore").length > 0) {
    	if($(".pageContentText").length > 0) {
           var h = $(".pageContentText")[0].scrollHeight;
        }
        var open = false;

        $(".readMore").click(function (e) {
            e.stopPropagation();

            if (!open) {
                $(".pageContentText").animate({
                    'height': h
                }, {
                    duration: 500,
                    complete: function () {
                        open = true;
                        $(".readMore").text("Close");
                    }
                });
            }
            else {
                $(".pageContentText").animate({
                    'height': '135px'
                }, {
                    duration: 500,
                    complete: function () {
                        open = false
                        $(".readMore").text("Read More");
                    }
                });
            }
        });
    }
	
	
	if($(".read-more").length > 0) {
    	if($(".pageContentText-second").length > 0) {
           var h = $(".pageContentText-second")[0].scrollHeight;
        }
        var open = false;

        $(".read-more").click(function (e) {
            e.stopPropagation();

            if (!open) {
                $(".pageContentText-second").animate({
                    'height': h
                }, {
                    duration: 500,
                    complete: function () {
                        open = true;
                        $(".read-more").text("Close");
                    }
                });
            }
            else {
                $(".pageContentText-second").animate({
                    'height': '135px'
                }, {
                    duration: 500,
                    complete: function () {
                        open = false
                        $(".read-more").text("Read More");
                    }
                });
            }
        });
    }
	
	// Hover state for top level menu item on desktop
	if($(".subMenu")[0]) {
		$( ".subMenu" ).hover(function() {
			var device = $('.detectDevice').css("float");
			if(device!=='left') {
				$( this ).parent('li').addClass('active');
			}
		}, function() {
			var device = $('.detectDevice').css("float");
			if(device!=='left') {
				$( this ).parent('li').removeClass('active');
			}
		});
	}

	// Open sub menu on tablet/mobile menu
	if($(".hasDropdown")[0]) {
		$('.hasDropdown').bind("click touchstart", function(e) {
		    var device = $('.detectDevice').css("float");
			if(device!=='none') {
				e.preventDefault();
				
				$('.headerContainer > .mainMenu > .mainMenuActive').css({ 'overflow-y': ''});
				$(this).parent('li').parent('ul').addClass('opened');
				$(this).parent('li').addClass('open');
			}
		});
	}

	// Close sub menu on tablet/mobile menu
	if($(".subMenuHeader")[0]) {
		$('.subMenuHeader').bind("click touchstart", function(e) {
			e.preventDefault();
		    var device = $('.detectDevice').css("float");
			if(device!=='none') {
				$(this).parent('ul').parent('li').parent('ul').removeClass('opened');
				$(this).parent('ul').parent('li').removeClass('open');
			}
		});
	}

	// Open/Close tablet/mobile menu
	if($(".menuToggle")[0]) {
		$('.menuToggle').click(function() {
		    var device = $('.detectDevice').css("float");
			if(device!=='none') {
				$(this).parent('ul').next('.mainMenu').toggleClass('mainMenuActive');
			}
		});
	}

	//Active homepage banner

	if($(".bxslider")[0]) {
		var slider = $('.bxslider').bxSlider({
	        auto: ($(".bxslider li").length > 1) ? true : false,
	        responsive: true,
	        mode: 'fade',
	        captions: true,
	        touchEnabled: false
	    });

	    // Hide homepage banner controls
    	$('.bx-controls').hide();

    	// Homepage banner previous slide button
    	$('.sliderPrev').click(function() {
        	slider.goToPrevSlide();
	    });

    	// Homepage banner next slide button
	    $('.sliderNext').click(function() {
	        slider.goToNextSlide();
	    });
	}

	// Open/close booking menu
	if($(".bookingButton")[0]) {
		$('.bookingButton').click(function() {
			$('.bookingBody').slideToggle();
		});

		$('.closeButton').click(function() {
			$('.bookingBody').slideToggle();
			$('.bookingMobileBg').delay(500).removeClass('bookingMobileBgOpen');
		});
	}

	// Open/close booking menu from page link
	if($(".openBookingForm")[0]) {
		$('.openBookingForm').click(function(e) {
			e.preventDefault();
			$('.bookingBody').slideToggle();
		});
	}

	// Open/Close booking form on mobile device
	if($(".bookNow")[0]) {
		$('.bookNow').click(function() {
			$('.bookingMobileBg').addClass('bookingMobileBgOpen');
			var intTop = $(window).scrollTop();
			intTop = (intTop-150) + 'px';
			$('.bookingContainer').css('top', intTop);
			$('.bookingBody').slideToggle();
		});
	}

	// Activate parallax banners
	IS_IPAD = (/ipad|android|android 3.0|xoom|sch-i800|playbook|tablet|kindle/i.test(navigator.userAgent.toLowerCase()));
	if (IS_IPAD==true) {
		$('.parallaxContainer').css('background-attachment', 'scroll');
	} else {
		$(window).stellar();
	}

	// Set the vertical teaser copy to have the same height
	if($(".verticalTeaser")[0]) {
		$('.verticalTeaser').find('h2').delay(1000).matchHeight();
		$('.verticalTeaser').find('h3').delay(1000).matchHeight();
		$('.verticalTeaser').find('h4').delay(1000).matchHeight();
	}

    	// Contact form functionality
	    jQuery(document).on( 'click', '.contactSubmit', function (e) {
	        e.preventDefault();
	        var subject = "Holiday Inn Gatwick Crawley - Enquiry - " + jQuery('.strSubject').val();
	        
	        var to = "neil@punch-creative.co.uk";
	        
	        var name = jQuery('#strName').val();
	        var email = jQuery('#strEmail').val();
	        var phone = jQuery('#strPhone').val();
	        var message = jQuery('#strMessage').val();
	        // var subject = jQuery('#strSubject').val();
	        
	        var terms = '';
                                                
			if($('input[name="terms"]:checked').length > 0) {
				terms = "Yes";
			} else {
				terms = "No";
			}

			var marketing = '';

			if($('input[name="marketing"]:checked').length > 0) {
				marketing = "Yes";
			} else {
				marketing = "No";
			}
	        
	        var how = '';

            $('input[name="contact"]:checked').each(function() {
               how = how + $(this).data('tag') + ', ';
            });
            
             
            if(how!=='') {
               how = how.slice(0, -2);
            }
            
            var group = '';
            
            $('input[name="day"]:checked').each(function() {
               group = group + $(this).data('tag') + ', ';
            });
            
            if(group!=='') {
                group = group.slice(0, -2);
            }
            
            message = message+'\r\n\r\n Telephone: '+phone+'\r\n\r\n How would you like to be contacted: '+how+'\r\n\r\n What time of day would you like to be contacted: '+group+'\r\n\r\n Terms Accepted: '+terms+'\r\n\r\n Marketing Accepted: '+marketing;
	        var errorMsg = 'Please check the following:<br /> <br />';
	
	        if( isValidEmailAddress( email ) && !email =='' && !name =='' && terms =="Yes" ) {
	        
	            jQuery.ajax({
	                type       : "POST",
	                data       : {subject : subject, to: to, name: name, email: email, phone: phone, body: message, },
	                url        : "/punch/contact",
	                beforeSend : function(){
	                },
	                success    : function(data){
	                    $('.contactMessage').find('p').html('Thank you. We will contact you shortly.');
	                    $('.contactMessage').removeClass('borderRed');
	                    $('.contactMessage').addClass('borderGold');
	                    $('.contactMessage').slideDown().delay(5000).slideUp();
	                },
	               error: function(data) {
	        		var errors = $.parseJSON(data.responseText);
	        		
	        		$.each(errors, function(key, value) {
				   $("#errors ul").append("<li>" + value + "</li>");
	        		});
	
	        		$("#errors").show();
        		}	                
	            });
	            
	        } else {
	        
	            if(email =='') {
	                errorMsg = errorMsg + 'Enter your email address.<br />';
	            }
	            if(name =='') {
	                errorMsg = errorMsg + 'Enter your name.<br />';
	            }
	            if(terms =='No') {
                    errorMsg = errorMsg + 'Please accept our terms and conditions.<br />';
                }
	            if(isValidEmailAddress( email )) {
	            } else {
	                errorMsg = errorMsg + 'Enter a valid email address.<br />';
	            }
	
	            $('.contactMessage').find('p').html(errorMsg);
	            $('.contactMessage').removeClass('borderGold');
	            $('.contactMessage').addClass('borderRed');
	            $('.contactMessage').slideDown().delay(5000).slideUp();
	            
	        }
	    });

	// Initialise masonry grid
    if ($(".grid")[0]) {
        var $grid = $('.grid').masonry({
          // options
          itemSelector: '.grid-item',
          percentPosition: true
        });

        $grid.imagesLoaded().progress( function() {
            $grid.masonry('layout');
        });

        // Initialise lightbox for the gallery
        $('.zoom-gallery').magnificPopup({
            delegate: 'a',
            type: 'image',
            closeOnContentClick: false,
            closeBtnInside: false,
            mainClass: 'mfp-with-zoom mfp-img-mobile',
            image: {
                verticalFit: true,
                titleSrc: function(item) {
                    return item.el.attr('title');
                }
            },
            gallery: {
                enabled: true
            },
            zoom: {
                enabled: true,
                duration: 300, // don't foget to change the duration also in CSS
                opener: function(element) {
                    return element.find('img');
                }
            }
            
        });
    }
    
    
    if($(".centerBlock")[0]) {
		$('.centerBlock').matchHeight();
	}

	// open close terms and conditions on forms 
    if($(".termsConditions")[0]) {
	    $(".termsConditions").click(function(){
            $(".terms-conditions-content").slideToggle();
	    });
    }

/*
	// Set up development carousel
	var owlCarouselNews = $('.owl-carousel');
    owlCarouselNews.owlCarousel({
	    loop:true,
	    margin:30,
	    responsiveClass:true,
	    responsive:{
	        0:{
	            items:1,
	            nav:false
	        },
	        600:{
	            items:2,
	            nav:false
	        },
	        1000:{
	            items:3,
	            nav:false
	        }
	    }
	});

    // Controls for development carsousel left and right buttons
	$('.navigationNewsLeft').click(function() {
	    owlCarouselNews.trigger('prev.owl.carousel');
	});

	$('.navigationNewsRight').click(function() {
	    owlCarouselNews.trigger('next.owl.carousel');
	});

	// Set up portfolio carousel
    var owlCarouselPortfolio = $('.owl-carousel-portfolio');
	owlCarouselPortfolio.owlCarousel({
	    center: true,
	    items:3,
	    loop:true,
	    responsive:{
	        0:{
	            items:1,
	            nav:false
	        },
	        624:{
	            items:1,
	            nav:false
	        },
	        625:{
	            items:3,
	            nav:false
	        },
	        1000:{
	            items:3,
	            nav:false
	        }
	    }
	});

	// Update hotel name in portfolio carousel after navigation
	owlCarouselPortfolio.on('changed.owl.carousel', function(test) {
     	setTimeout(function() {var hotelName = $(".owl-item.center").find("div.item").data('hotel-name');
     	console.log(hotelName);
		$("h3.portfolioName").html(hotelName);}, 100);
	});

	// Controls for portfolio carsousel left and right buttons
	$('.navigationPortfolioLeft').click(function() {
	    owlCarouselPortfolio.trigger('prev.owl.carousel');
	});

	$('.navigationPortfolioRight').click(function() {
	    owlCarouselPortfolio.trigger('next.owl.carousel');
	});

	// Set up support team carousel
    var owlCarouselSupport = $('.owl-carousel-support-team');
	owlCarouselSupport.owlCarousel({
	    loop:true,
	    margin:30,
	    responsiveClass:true,
	    responsive:{
	        0:{
	            items:1,
	            nav:false
	        },
	        600:{
	            items:2,
	            nav:false
	        },
	        1000:{
	            items:3,
	            nav:false
	        }
	    }
	});

	// Controls for support team carsousel left and right buttons
	$('.navigationSupportLeft').click(function() {
	    owlCarouselSupport.trigger('prev.owl.carousel');
	});

	$('.navigationSupportRight').click(function() {
	    owlCarouselSupport.trigger('next.owl.carousel');
	});

	// Set up transformicon in the menu button
	transformicons.add('.tcon');

	// Activate parallax banners
	IS_IPAD = (/ipad|android|android 3.0|xoom|sch-i800|playbook|tablet|kindle/i.test(navigator.userAgent.toLowerCase()));
	if (IS_IPAD==true) {
		$('.parallaxContainer').css('background-attachment', 'scroll');
	} else {
		$(window).stellar();
	}

	// Manage group portfolio drop down filters
	if($(".filterSelectorHeader")[0]) {
		$(".filterSelectorHeader").click(function () {
		    $(this).parent().find('.filterSelectorList').slideToggle("slow");
		    $(this).find('.filterSelectorArrow i').toggleClass('fa-arrow-down');
		    $(this).find('.filterSelectorArrow i').toggleClass('fa-arrow-up');
		});

		$('.filterSelectorList li').click(function () {
		    $(this).parent().slideUp("slow");
		    $(this).parent().parent().find('.filterSelectorHeader .filterSelectorArrow i').addClass('fa-arrow-down');
		    $(this).parent().parent().find('.filterSelectorHeader .filterSelectorArrow i').removeClass('fa-arrow-up');
		});
	}

	// Activate group portfolio filter system
	if($("#portfolioMix")[0]) {
		$('#portfolioMix').mixItUp();
	}

	// Get current URL
	var loc = window.location.pathname;

	// Set menu items as active if on the same page
	$('.hpNavMenu ul').find('a').each(function() {
		$(this).toggleClass('active', $(this).attr('href') == loc);
	});

	$('.footerSitemap').find('a').each(function() {
		$(this).parent().toggleClass('active', $(this).attr('href') == loc);
	});

	$('.footerBarLinks').find('a').each(function() {
		$(this).toggleClass('active', $(this).attr('href') == loc);
	});

	// Set sections of the press section to have the same height
	if($(".pressBoxHeaderContainer")[0]) {
		$('.pressBoxHeaderContainer').matchHeight();
		$('.pressBox').matchHeight();
	}

	// Set sections of the developments section to have the same height
	if($(".hpNewsBox")[0]) {
		$('.hpNewsBox').matchHeight();
	}

	// Set sections of the support team section to have the same height
	if($(".hpSupportBox")[0]) {
		$('.hpSupportBox').matchHeight();
	}

	// Set the content sections to have the same height
	if($(".spContent")[0]) {
		$('.spContent').matchHeight();
	}

	// Set up and activate the Twitter feed in the footer
	if($(".tweet")[0]) {
		$('.tweet').twittie({
			'apiPath':'../js/api/tweet.php',
			'username': "SHG_Hotels",
		    'count': 2,
		    'hideReplies': true
		});
	}

	$(window).load(function(){
		// Remove the # from the hash, as different browsers may or may not include it
		var hash = location.hash.replace('#','');

		if(hash != ''){
			var newHash = hash.replace('Dev','');
			// Clear the hash in the URL
			// location.hash = '';   // delete front "//" if you want to change the address bar
			var device = $('.detectDevice').css("float");
			if(device=='left' || device=='right') {
				$('body, html').animate({scrollTop: $('#'+newHash).offset().top-60}, 'slow');
			} else {
				$('body, html').animate({scrollTop: $('#'+newHash).offset().top-120}, 'slow');
			}

		}
	});

	// Add subscription form funcionality
	$("form").submit(function (e) {
    	e.preventDefault();
	    var formData = {
	        'success'              : $('input[name=ysnSuccess]').val(),
	        'email'             : $('input[name=strSubscription]').val()
	    };

	    // process the form
	    $.ajax({
	        type        : 'POST', // define the type of HTTP verb we want to use (POST for our form)
	        url         : 'http://shg.dev.gridhosted.co.uk/inc/ajax-subscription.php', // the url where we want to POST
	        data        : formData, // our data object
			success    : function(data){
				if(data !== '') {
					// If the error message field is already open just change the message.
					if ($('.subscriptionMessage').is(':visible')) {
						$('.subscriptionMessage p').html(data);
					} else {
						$('.subscriptionMessage p').html(data);
						$('.subscriptionMessage').slideDown();
						$('.subscriptionMessage').delay(6000).slideUp();
					}
				}			
			},
			error     : function(jqXHR, textStatus, errorThrown) {
				alert(jqXHR + " :: " + textStatus + " :: " + errorThrown);
			}
	    })
	}); */
});

// Set up and activate cookie bar
//window.cookieconsent_options = {"message":"This website uses cookies to ensure you get the best experience on our website","dismiss":"I Accept","learnMore":"More info","link":"/privacy-policy","theme":"dark-bottom"};

// When clicked scroll to the top of the page
$(document).on( 'click', '.fixedToTop', function () {
	$('body, html').animate({scrollTop: $('body').offset().top}, 'slow');
});


/*
// Set up and activate cookie bar
// window.cookieconsent_options = {"message":"This website uses cookies to ensure you get the best experience on our website","dismiss":"I Accept","learnMore":"More info","link":"/privacy-statement","theme":"dark-bottom"};

// When clicked open the main menu
$(document).on( 'click', '.tcon-menu--xcross', function () {
	$('.hpNavMenuContainer').toggleClass('hpNavMenuContainerShow');
	var intTop = $(window).scrollTop();
	intTop = (intTop+140) + 'px';
	$('.hpNavMenu').css('top', intTop);
});

// When clicked close the main menu
$(document).on( 'click', '.closeMenu', function () {
	$('.hpNavMenuContainer').toggleClass('hpNavMenuContainerShow');
	transformicons.revert('.tcon-menu--xcross');
});

// When clicked scroll to the homepage teasers
$(document).on( 'click', '.hpExplore', function () {
	var device = $('.detectDevice').css("float");
	if(device=='left') {
		$('body, html').animate({scrollTop: 590}, 'slow');
	} else if(device=='right') {
		$('body, html').animate({scrollTop: 390}, 'slow');
	} else {
		$('body, html').animate({scrollTop: $('.hpIntroContainer').offset().top-120}, 'slow');
	}
});

// When clicked scroll to the top of the page
$(document).on( 'click', '.mobileToTop', function () {
	$('body, html').animate({scrollTop: $('body').offset().top}, 'slow');
});

// Update the group portfolio top level filter content
$(document).on( 'click', '.locationList .filter', function () {
	var text = $(this).find('p').html();
	$(this).parent().prev('.filterSelectorHeader').find('p').html(text);
	$('#brandFilter p').html('FILTER BY BRAND');
});

// Update the group portfolio top level filter content
$(document).on( 'click', '.brandList .filter', function () {
	var text = $(this).find('p').html();
	$(this).parent().prev('.filterSelectorHeader').find('p').html(text);
	$('#locationFilter p').html('FILTER BY LOCATION');
});

// When clicked add email address to subscription database table
$(document).on( 'click', '.subscriptionSubmit', function () {
	$('.subscriptionForm').submit();
}); */